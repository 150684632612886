.modal-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    height: auto !important; 
    overflow-y: auto !important; 
       /* max-height: 100vh !important; */
}

@media (max-width: 767px) {
    .modal-content {
        padding: 10px;
        margin: 10px;
    }
}

.titleStyle{

    color: #252424 !important;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center !important;
    margin-bottom: 4%;
}
.formPosition{
    text-align: start;
  
}
.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100% ;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.address-options {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
}

.address-option {
    cursor: pointer;
    margin-bottom: 5px;
}



.address-option p {
    margin: 0;
    font-size: 14px;
}

.close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #000;

}

.close:focus {
    outline: none;
}

.save{
   margin-top: 35px !important;
   padding-top: 10px !important;
   padding-bottom: 10px !important;
}

.put{
  
    /* padding-right: 100px !important; */
    padding-top: 6px;
    padding-bottom: 6px;
    /* border-radius: 2; */
    /* border-color: #868484; */
}