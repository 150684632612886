.color-options {
    display: flex;
    margin-top: 10px;
}

.color-option {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
}

.color-option.selected {
    border: 5px solid #000;
}

@media (max-width: 768px) {
    .color-options {
        flex-wrap: wrap;
        max-width: 300px;
        justify-content: center;

    }
}