/*!
 * Slider for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
 $color: #FD8D27;
 .slider {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  
    &.slider-horizontal {
      width: 210px;
      height: 20px;
  
      .slider-track {
        height: 15px;
        left: 0;
        margin-top: -5px;
        top: 50%;
        width: 100%;
      }
  
      .slider-selection {
        height: 100%;
        top: 0;
        bottom: 0;
      }
  
      .slider-handle {
        margin-left: -12px;
        margin-top: 2px;
      }
    }
  }
  
  .left-round {
    margin-left: 2px !important;
  }
  
  .slider {
    &.slider-horizontal .slider-handle.triangle {
      border-width: 0 10px 10px 10px;
      width: 0;
      height: 0;
      border-bottom-color: #0480be;
      margin-top: 0;
    }
  
    &.slider-vertical {
      height: 210px;
      width: 20px;
  
      .slider-track {
        width: 10px;
        height: 100%;
        margin-left: -5px;
        left: 50%;
        top: 0;
      }
  
      .slider-selection {
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
      }
  
      .slider-handle {
        margin-left: -5px;
        margin-top: -10px;
  
        &.triangle {
          border-width: 10px 0 10px 10px;
          width: 1px;
          height: 1px;
          border-left-color: #0480be;
          margin-left: 0;
        }
      }
    }
  
    input {
      display: none;
    }
  
    .tooltip-inner {
      white-space: nowrap;
    }
  }
  
  .slider-track {
    position: absolute;
    cursor: pointer;
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f9f9f9));
    background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
  }
  
  .slider-selection {
    -moz-box-sizing: border-box;
    background: none repeat scroll 0 0 $color;
    border-radius: 15px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
    position: absolute;
  }
  
  .slider-handle {
    background: #fff;
    box-shadow: none;
    height: 10px;
    opacity: 1;
    position: absolute;
    width: 10px;
  
    &.round {
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }
  
    &.triangle {
      background: transparent none;
    }
  }
  