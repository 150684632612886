/* PaymentModal.css */

.payment-modal-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    height: 300px !important;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.payment-options {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0;
}

.payment-option {
    cursor: pointer;
    margin-bottom: 5px;
    padding: 10px;
}

.payment-option.active {
    background-color: gold;
    outline: 4px solid gold;
    border-radius: 10px;
}

.payment-option:hover {
    outline: 4px solid gold;
    border-radius: 10px;
}


.payment-option img {
    width: 80px;
    height: 60px;
    margin-bottom: 10px;
}

.payment-option p {
    margin: 0;
    font-size: 14px;
}

.close {
    background: none;
    border: none;
    font-size: 32px;
    cursor: pointer;
    color: #000;
}

.close:focus {
    outline: none;
}

.modal-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 20px;
}

/* @media screen and (max-width: 480px) {
    .modal-content {
        background-color: #fff;
        border-radius: 8px;
        padding: 20px;
        height: 500px !important;
        text-align: center;
        position: relative;
        overflow: hidden;
    }

    .payment-options {
        flex-direction: column;
        row-gap: 20px;
    }
} */