div {
    &.pp_default {
      .pp_top {
        height: 13px;
  
        .pp_middle, .pp_left, .pp_right {
          height: 13px;
        }
      }
  
      .pp_bottom {
        height: 13px;
  
        .pp_left, .pp_middle, .pp_right {
          height: 13px;
        }
      }
  
      .pp_top {
        .pp_left {
        }
  
        .pp_middle {
        }
  
        .pp_right {
        }
      }
  
      .pp_content .ppt {
        color: #f8f8f8;
      }
  
      .pp_content_container {
        .pp_left {
          padding-left: 13px;
        }
  
        .pp_right {
          padding-right: 13px;
        }
      }
  
      .pp_next:hover {
        cursor: pointer;
      }
  
      .pp_previous:hover {
        cursor: pointer;
      }
  
      .pp_expand {
        cursor: pointer;
        width: 28px;
        height: 28px;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      .pp_contract {
        cursor: pointer;
        width: 28px;
        height: 28px;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      .pp_close {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
  
      .pp_gallery ul li a {
        border: 1px solid #aaa;
      }
  
      .pp_social {
        margin-top: 7px;
      }
  
      .pp_gallery a {
        &.pp_arrow_previous, &.pp_arrow_next {
          position: static;
          left: auto;
        }
      }
  
      .pp_nav {
        .pp_play {
          height: 30px;
          width: 30px;
        }
  
        .pp_pause {
          height: 30px;
          width: 30px;
          background-position: -51px -29px;
        }
      }
  
      a {
        &.pp_arrow_previous {
          height: 20px;
          width: 20px;
          margin: 4px 0 0;
        }
  
        &.pp_arrow_next {
          height: 20px;
          width: 20px;
          margin: 4px 0 0;
          left: 52px;
          background-position: -82px -3px;
        }
      }
  
      .pp_content_container .pp_details {
        margin-top: 5px;
      }
  
      .pp_nav {
        clear: none;
        height: 30px;
        width: 110px;
        position: relative;
  
        .currentTextHolder {
          font-family: Georgia;
          font-style: italic;
          color: #999;
          font-size: 11px;
          left: 75px;
          line-height: 25px;
          position: absolute;
          top: 2px;
          margin: 0;
          padding: 0 0 0 10px;
        }
      }
  
      .pp_close:hover {
        opacity: 0.7;
      }
  
      .pp_nav {
        .pp_play:hover, .pp_pause:hover {
          opacity: 0.7;
        }
      }
  
      .pp_arrow_next:hover, .pp_arrow_previous:hover {
        opacity: 0.7;
      }
  
      .pp_description {
        font-size: 11px;
        font-weight: 700;
        line-height: 14px;
        margin: 5px 50px 5px 0;
      }
  
      .pp_bottom {
        .pp_left {
        }
  
        .pp_middle {
        }
  
        .pp_right {
        }
      }
  
      .pp_loaderIcon {
      }
    }
  
    &.light_rounded {
      .pp_top {
        .pp_left {
        }
  
        .pp_right {
        }
      }
  
      .pp_next:hover {
        cursor: pointer;
      }
  
      .pp_previous:hover {
        cursor: pointer;
      }
  
      .pp_expand {
        cursor: pointer;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      .pp_contract {
        cursor: pointer;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      .pp_close {
        width: 75px;
        height: 22px;
        cursor: pointer;
      }
  
      .pp_nav {
        .pp_play {
          height: 15px;
          width: 14px;
        }
  
        .pp_pause {
          height: 15px;
          width: 14px;
        }
      }
  
      .pp_arrow_previous {
      }
  
      .pp_arrow_next {
      }
  
      .pp_bottom {
        .pp_left {
        }
  
        .pp_right {
        }
      }
    }
  
    &.dark_rounded {
      .pp_top {
        .pp_left {
        }
  
        .pp_right {
        }
      }
  
      .pp_content_container {
        .pp_left {
        }
  
        .pp_right {
        }
      }
  
      .pp_next:hover {
        cursor: pointer;
      }
  
      .pp_previous:hover {
        cursor: pointer;
      }
  
      .pp_expand {
        cursor: pointer;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      .pp_contract {
        cursor: pointer;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      .pp_close {
        width: 75px;
        height: 22px;
        cursor: pointer;
      }
  
      .pp_description {
        margin-right: 85px;
        color: #fff;
      }
  
      .pp_nav {
        .pp_play {
          height: 15px;
          width: 14px;
        }
  
        .pp_pause {
          height: 15px;
          width: 14px;
        }
      }
  
      .pp_arrow_previous {
      }
  
      .pp_arrow_next {
      }
  
      .pp_bottom {
        .pp_left {
        }
  
        .pp_right {
        }
      }
  
      .pp_loaderIcon {
      }
    }
  
    &.dark_square {
      .pp_left, .pp_middle, .pp_right, .pp_content {
        background: #000;
      }
  
      .pp_description {
        color: #fff;
        margin: 0 85px 0 0;
      }
  
      .pp_loaderIcon {
      }
  
      .pp_expand {
        cursor: pointer;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      .pp_contract {
        cursor: pointer;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      .pp_close {
        width: 75px;
        height: 22px;
        cursor: pointer;
      }
  
      .pp_nav {
        clear: none;
  
        .pp_play {
          height: 15px;
          width: 14px;
        }
  
        .pp_pause {
          height: 15px;
          width: 14px;
        }
      }
  
      .pp_arrow_previous {
      }
  
      .pp_arrow_next {
      }
  
      .pp_next:hover {
        cursor: pointer;
      }
  
      .pp_previous:hover {
        cursor: pointer;
      }
    }
  
    &.light_square {
      .pp_expand {
        cursor: pointer;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      .pp_contract {
        cursor: pointer;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      .pp_close {
        width: 75px;
        height: 22px;
        cursor: pointer;
      }
  
      .pp_nav {
        .pp_play {
          height: 15px;
          width: 14px;
        }
  
        .pp_pause {
          height: 15px;
          width: 14px;
        }
      }
  
      .pp_arrow_previous {
      }
  
      .pp_arrow_next {
      }
  
      .pp_next:hover {
        cursor: pointer;
      }
  
      .pp_previous:hover {
        cursor: pointer;
      }
    }
  
    &.facebook {
      .pp_top {
        .pp_left {
        }
  
        .pp_middle {
        }
  
        .pp_right {
        }
      }
  
      .pp_content_container {
        .pp_left {
        }
  
        .pp_right {
        }
      }
  
      .pp_expand {
        cursor: pointer;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      .pp_contract {
        cursor: pointer;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      .pp_close {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
  
      .pp_description {
        margin: 0 37px 0 0;
      }
  
      .pp_loaderIcon {
      }
  
      .pp_arrow_previous {
        height: 22px;
        margin-top: 0;
        width: 22px;
  
        &.disabled {
          background-position: 0 -96px;
          cursor: default;
        }
      }
  
      .pp_arrow_next {
        height: 22px;
        margin-top: 0;
        width: 22px;
  
        &.disabled {
          background-position: -32px -96px;
          cursor: default;
        }
      }
  
      .pp_nav {
        margin-top: 0;
  
        p {
          font-size: 15px;
          padding: 0 3px 0 4px;
        }
  
        .pp_play {
          height: 22px;
          width: 22px;
        }
  
        .pp_pause {
          height: 22px;
          width: 22px;
        }
      }
  
      .pp_next:hover {
        cursor: pointer;
      }
  
      .pp_previous:hover {
        cursor: pointer;
      }
  
      .pp_bottom {
        .pp_left {
        }
  
        .pp_middle {
        }
  
        .pp_right {
        }
      }
    }
  
    &.pp_pic_holder a:focus {
      outline: none;
    }
  
    &.pp_overlay {
      background: #000;
      display: none;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 9500;
    }
  
    &.pp_pic_holder {
      display: none;
      position: absolute;
      width: 100px;
      z-index: 10000;
    }
  }
  
  .pp_content {
    height: 40px;
    min-width: 40px;
  }
  
  * html .pp_content {
    width: 40px;
  }
  
  .pp_content_container {
    position: relative;
    text-align: left;
    width: 100%;
  
    .pp_left {
      padding-left: 20px;
    }
  
    .pp_right {
      padding-right: 20px;
    }
  
    .pp_details {
      float: left;
      margin: 10px 0 2px;
    }
  }
  
  .pp_description {
    display: none;
    margin: 0;
  }
  
  .pp_social {
    float: left;
    margin: 0;
  
    .facebook {
      float: left;
      margin-left: 5px;
      width: 55px;
      overflow: hidden;
    }
  
    .twitter {
      float: left;
    }
  }
  
  .pp_nav {
    clear: right;
    float: left;
    margin: 3px 10px 0 0;
  
    p {
      float: left;
      white-space: nowrap;
      margin: 2px 4px;
    }
  
    .pp_play, .pp_pause {
      float: left;
      margin-right: 4px;
      text-indent: -10000px;
    }
  }
  
  a {
    &.pp_arrow_previous, &.pp_arrow_next {
      display: block;
      float: left;
      height: 15px;
      margin-top: 3px;
      overflow: hidden;
      text-indent: -10000px;
      width: 14px;
    }
  }
  
  .pp_hoverContainer {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2000;
  }
  
  .pp_gallery {
    display: none;
    left: 50%;
    margin-top: -50px;
    position: absolute;
    z-index: 10000;
  
    div {
      float: left;
      overflow: hidden;
      position: relative;
    }
  
    ul {
      float: left;
      height: 35px;
      position: relative;
      white-space: nowrap;
      margin: 0 0 0 5px;
      padding: 0;
  
      a {
        border: 1px rgba(0, 0, 0, 0.5) solid;
        display: block;
        float: left;
        height: 33px;
        overflow: hidden;
  
        img {
          border: 0;
        }
      }
    }
  
    li {
      display: block;
      float: left;
      margin: 0 5px 0 0;
      padding: 0;
  
      &.default a {
        display: block;
        height: 33px;
        width: 50px;
      }
    }
  
    .pp_arrow_previous, .pp_arrow_next {
      margin-top: 7px !important;
    }
  }
  
  a {
    &.pp_next {
      display: block;
      float: right;
      height: 100%;
      text-indent: -10000px;
      width: 49%;
    }
  
    &.pp_previous {
      display: block;
      float: left;
      height: 100%;
      text-indent: -10000px;
      width: 49%;
    }
  
    &.pp_expand, &.pp_contract {
      cursor: pointer;
      display: none;
      height: 20px;
      position: absolute;
      right: 30px;
      text-indent: -10000px;
      top: 10px;
      width: 20px;
      z-index: 20000;
    }
  
    &.pp_close {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      line-height: 22px;
      text-indent: -10000px;
    }
  }
  
  .pp_loaderIcon {
    display: block;
    height: 24px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 24px;
    margin: -12px 0 0 -12px;
  }
  
  #pp_full_res {
    line-height: 1 !important;
  
    .pp_inline {
      text-align: left;
  
      p {
        margin: 0 0 15px;
      }
    }
  }
  
  div {
    &.ppt {
      color: #fff;
      display: none;
      font-size: 17px;
      z-index: 9999;
      margin: 0 0 5px 15px;
    }
  
    &.pp_default .pp_content, &.light_rounded .pp_content {
      background-color: #fff;
    }
  
    &.pp_default #pp_full_res .pp_inline {
      color: #000;
    }
  
    &.light_rounded {
      .pp_content .ppt, #pp_full_res .pp_inline {
        color: #000;
      }
    }
  
    &.light_square {
      .pp_content .ppt, #pp_full_res .pp_inline {
        color: #000;
      }
    }
  
    &.facebook {
      .pp_content .ppt, #pp_full_res .pp_inline {
        color: #000;
      }
    }
  
    &.pp_default .pp_gallery ul li {
      a:hover, &.selected a {
        border-color: #fff;
      }
    }
  }
  
  .pp_gallery {
    ul a:hover, li.selected a {
      border-color: #fff;
    }
  }
  
  div {
    &.pp_default .pp_details, &.light_rounded .pp_details, &.dark_rounded .pp_details, &.dark_square .pp_details, &.light_square .pp_details, &.facebook .pp_details {
      position: relative;
    }
  
    &.light_rounded {
      .pp_top .pp_middle {
        background: #fff;
      }
  
      .pp_content_container {
        .pp_left, .pp_right {
          background: #fff;
        }
      }
  
      .pp_bottom .pp_middle {
        background: #fff;
      }
    }
  
    &.light_square {
      .pp_left, .pp_middle, .pp_right, .pp_content {
        background: #fff;
      }
    }
  
    &.facebook .pp_content {
      background: #fff;
    }
  
    &.light_rounded .pp_description, &.light_square .pp_description {
      margin-right: 85px;
    }
  
    &.light_rounded .pp_gallery a {
      &.pp_arrow_previous, &.pp_arrow_next {
        margin-top: 12px !important;
      }
    }
  
    &.dark_rounded .pp_gallery a {
      &.pp_arrow_previous, &.pp_arrow_next {
        margin-top: 12px !important;
      }
    }
  
    &.dark_square .pp_gallery a {
      &.pp_arrow_previous, &.pp_arrow_next {
        margin-top: 12px !important;
      }
    }
  
    &.light_square .pp_gallery a {
      &.pp_arrow_previous, &.pp_arrow_next {
        margin-top: 12px !important;
      }
    }
  
    &.light_rounded .pp_arrow_previous.disabled, &.dark_rounded .pp_arrow_previous.disabled, &.dark_square .pp_arrow_previous.disabled, &.light_square .pp_arrow_previous.disabled {
      background-position: 0 -87px;
      cursor: default;
    }
  
    &.light_rounded .pp_arrow_next.disabled, &.dark_rounded .pp_arrow_next.disabled, &.dark_square .pp_arrow_next.disabled, &.light_square .pp_arrow_next.disabled {
      background-position: -22px -87px;
      cursor: default;
    }
  
    &.light_rounded .pp_loaderIcon, &.light_square .pp_loaderIcon {
    }
  
    &.dark_rounded {
      .pp_top .pp_middle, .pp_content, .pp_bottom .pp_middle {
      }
  
      .currentTextHolder {
        color: #c4c4c4;
      }
    }
  
    &.dark_square .currentTextHolder {
      color: #c4c4c4;
    }
  
    &.dark_rounded #pp_full_res .pp_inline, &.dark_square #pp_full_res .pp_inline {
      color: #fff;
    }
  }
  
  .pp_top, .pp_bottom {
    height: 20px;
    position: relative;
  }
  
  * html {
    .pp_top, .pp_bottom {
      padding: 0 20px;
    }
  }
  
  .pp_top .pp_left, .pp_bottom .pp_left {
    height: 20px;
    left: 0;
    position: absolute;
    width: 20px;
  }
  
  .pp_top .pp_middle, .pp_bottom .pp_middle {
    height: 20px;
    left: 20px;
    position: absolute;
    right: 20px;
  }
  
  * html {
    .pp_top .pp_middle, .pp_bottom .pp_middle {
      left: 0;
      position: static;
    }
  }
  
  .pp_top .pp_right, .pp_bottom .pp_right {
    height: 20px;
    left: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
  }
  
  .pp_fade, .pp_gallery li.default a img {
    display: none;
  }
  